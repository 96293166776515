import React, {createContext, ReactNode, useReducer,} from "react";

type UpdaterState = {
    [key: string]: string;
};

type Action = {
    type: "UPDATE_KEY";
    key: string;
};

type UpdaterContextType = {
    state: UpdaterState;
    updateKey: (key: string) => void;
};

export const UpdaterContext = createContext<UpdaterContextType | undefined>(
    undefined
);

const updaterReducer = (state: UpdaterState, action: Action): UpdaterState => {
    switch (action.type) {
        case "UPDATE_KEY":
            return {
                ...state,
                [action.key]: state[action.key] === "a" ? "b" : "a",
            };
        default:
            return state;
    }
};

type UpdaterProviderProps = {
    children: ReactNode;
};

export const UpdaterProvider = ({children}: UpdaterProviderProps) => {
    const [state, dispatch] = useReducer(updaterReducer, {});

    const updateKey = (key: string) => {
        dispatch({type: "UPDATE_KEY", key});
    };

    return (
        <UpdaterContext.Provider value={{state, updateKey}}>
            {children}
        </UpdaterContext.Provider>
    );
};
