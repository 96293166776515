'use client';

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import React, {useEffect, useState} from "react";
import {useSafeTranslations} from "@/hooks/use-safe-translations";
import {useTranslationsStore} from "@/stores/translations.store";
import {ToastContainer} from "react-toastify";
import {UpdaterProvider} from "@/providers/updater.provider";

interface ClientSideProviderProps {
    children: React.ReactNode;
}

export default function ClientSideProviders({children}: ClientSideProviderProps) {
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    }));

    const t = useSafeTranslations('common');
    const {setT} = useTranslationsStore();

    useEffect(() => {
        setT(t);
    }, [t]);

    return (
        <>
            <UpdaterProvider>
                <QueryClientProvider client={queryClient}>
                    {children}
                </QueryClientProvider>
            </UpdaterProvider>
            <ToastContainer toastClassName='toast-lsm'/>
        </>
    );
}
