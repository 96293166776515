import {useTranslations} from "next-intl";
import {UseTranslations} from "@/types/use-translations";

export const useSafeTranslations = (namespace: string): UseTranslations => {
    const t = useTranslations(namespace);

    if (t === undefined) {
        return ((key: string) => key) as UseTranslations;
    }

    return t;
}
